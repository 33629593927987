import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/Index'
import SEO from '../components/SEO'
import Style from '../styles/pages/sitemap.module.scss'

class PrivacyPolicy extends React.Component {

  render() {
    const { data } = this.props;
    const staticPrismicTexts = data.staticText.edges[0].node.data.texts;
    const content = data.allPrismicPagePrivacypolicy.edges[0].node.data;
    return (
      <>
        <Layout
          context={this.props.pageContext}
          pageTitle={content.title.text}
          _t={staticPrismicTexts}
        >
          <SEO
            pageTitle={content.title.text}
            pageDescription={content.title.text}
            context={this.props.pageContext}
          />
          <div className="page">
            <section className="regular">
              <div className={`page-head`}>
                <h2>{content.title.text}</h2>
              </div>
              <div
                style={{ paddingBottom: 200 }}
                className={`${Style.container} container`}
                dangerouslySetInnerHTML={{ __html: content.text.html }}
              >
              </div>
            </section>
          </div>
        </Layout>
      </>
    );
  }
}

export default PrivacyPolicy;
export const pageQuery = graphql`
  query privacyPolicyPage($locale: String!) {
    allPrismicPagePrivacypolicy(filter: {lang: {eq: $locale}}) {
      edges {
        node {
          data {
            text {
              html
              text
            }
            title {
              text
            }
          }
        }
      }
    }
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
  }
`;